<template>
  <div class="submission">
    <error :err="error" />
    <form-generator
      :data="book"
      :elements="elements"
      :handleUpdate="handleUpdate"
    />
  </div>
</template>

<script>
import FormGenerator from "@/components/form-generator";

export default {
  name: "submission",
  props: {
    handleUpdate: Function,
    book: Object
  },
  components: {
    FormGenerator
  },
  data() {
    return {
      error: null
    };
  },
  computed: {
    elements() {
      return [
        {
          key: "notes",
          label: "notes",
          type: "text"
        },
        {
          key: "agree",
          html: `${this.$t(
            "i-agree-to"
          )} <a href="https://ebookpartnership.com/terms" target="_blank">${this.$t(
            "terms-and-conditions"
          )}</a>`,
          type: "checkbox",
          className: "mt-4 float-left"
        },
        {
          text: "submit",
          type: "button",
          className: "mt-5 float-right px-3",
          events: {
            click: () => {
              this.error = null;
              if (!this.book.agree) {
                return (this.error = this.$t(
                  "validation-errors.agree-required"
                ));
              }

              this.$emit("submit");
            }
          }
        }
      ];
    }
  }
};
</script>

<style></style>
